import { useEffect, useState } from "react";
import { onPollUpdate } from "./firebase/on-poll-update";
import { useParams ,useSearch} from "wouter";
import { LoadingDNA } from ":src/components/loaders";
import { addPollAnswer } from "./firebase/add-poll-asnwer";
import { global } from ":global";
import { unstable_batchedUpdates } from "react-dom";
import { Button } from "primereact/button";
import {Title } from ':src/components/title'

export default function Poll(props: { displayOnly?: boolean }) {
  const params = useParams<{ eventId: string }>();
  const searchString = useSearch();
  const vertical = searchString == 'vertical' 
  const { eventId } = params;
  const [loading, setLoading] = useState(true);
  const [ans, setAns] = useState(-1 as number);
  const [oldAns, setOldAns] = useState(-1 as number);
  const [submitted, setSubmitted] = useState(false as boolean);
  const [total, setTotal] = useState(0 as number);
  const [poll, setPoll] = useState(
    {} as { question: string; choices: { title: string; value: number }[] }
  );
  const [max, setMax] = useState(-1 as number)
  const [notFound, setNotFound] = useState(false as boolean);

  useEffect(() => {
    try {
      if (eventId === undefined) {
        setLoading(false);
        setNotFound(true);

        return;
      }
      onPollUpdate({
        eventId: eventId as string,
        onUpdate: onPollChange,
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  function onPollChange(val: any) {
    if (!val) {
      setLoading(false);
      setNotFound(true);
      return;
    }
    unstable_batchedUpdates(() => {
      setPoll(val);
      let totalVal = 0;
      let maxNumber = 0; 
      let maxIndex = -1;
      val.choices.forEach((e: any, i :number) => {
        totalVal += e.value;
        if(e.value > maxNumber){ 
          maxNumber = e.value
          maxIndex = i 
        }
      });

      setMax(maxIndex)

      setTotal(totalVal);
      setLoading(false);
      if (props.displayOnly) {
        global.store.actions.qr_code_bar.setCodeSubRoute(
          `/play/poll/${eventId}`
        );
      }
    });
  }
  function getPercentage(value: number) {
    if(total == 0) return (0).toFixed(2)
    return ((value / total) * 100).toFixed(2);
  }
  function handleAnsChange(i: number) {
    setAns(i);
  }
  async function handlePollSubmit() {
    setSubmitted(true);
    setLoading(true);
    const ansIndex = ans;
    if (ansIndex == -1) return;
    const val = poll.choices[ansIndex].value + 1;
    setLoading(true);
    await addPollAnswer({ eventId: eventId as string, ansIndex, val });
    setOldAns(ansIndex);
    setLoading(false);
  }
  async function handlePollBack() {
    setSubmitted(false);

    const ansIndex = oldAns;
    if (ansIndex == -1) return;
    const val = poll.choices[ansIndex].value - 1;
    setLoading(true);
    addPollAnswer({ eventId: eventId as string, ansIndex, val }).finally(() =>
      setLoading(false)
    );
  }

  return (
    <div className="flex flex-col w-full h-full overflow-hidden">
      {loading ? (
        <div className="flex flex-col w-full h-full overflow-hidden  items-center justify-center">
          <LoadingDNA width={"30%"} height={"30%"} />
        </div>
      ) : (
        <>
          {poll && !notFound ? (
            <>
              {props.displayOnly ? (
                <div >
                
                    <Title title={poll.question}/>
                   
                  <div className={vertical? 'flex flex-colum flex-wrap-reverse overflow-y-scroll justify-between h-full':''}>
                  {poll.choices &&
                    poll.choices.map((choice, index) => (
                      <div key={`poll-${eventId}-${index}`} className="mt-7">
                        <h1 className={"text-[#0D2937] font-bold	 text-left	mb--3  text-2xl " + (vertical? ' text-center text-2xl': '')} style={vertical? {maxWidth:' 320px'} :{}}>
                          {choice.title}{" "}
                        </h1>
                        <div className={(vertical? "flex flex-col " :"flex flex-row ")}>
                          <div
                            style={
                              vertical? {
                                height:`calc(${getPercentage(choice.value)}vh - ${3.5 * parseInt(getPercentage(choice.value))}px  ) `
                              }:
                              {

                              width: `${getPercentage(choice.value)}% `,
                            }}
                            className={"h-6 w-6 bg-[#0D2937] mt-6 mr-2  " + (vertical? " ml-auto mr-auto ":' ')+ (index == max? 'bg-[#8BB1AC]':'')}
                          ></div>
                          <h2 className={"text-[#0D2937] text-left	text-2xl "+ (vertical? ' text-center ':' ')+ (index == max? 'text-[#8BB1AC]':'')}>
                            {getPercentage(choice.value)}%
                          </h2>
                        </div>
                      </div>
                    ))}
                    
                    </div>
                </div>
              ) : (
                <div className="p-4">
                                        <Title title={poll.question} play/>  

                  {!submitted ? (
                    <>
                    {/* input desired choice */}

                      {poll.choices &&
                        poll.choices.map((choice, i: number) => (
                          <div
                            key={`poll-${eventId}-${i}`}
                            className={
                              " p4 pl-4 pr-8 m2 flex flex-row cursor-pointer font-light	 text-xl	 text-[#242424] border-solid border-1 border-[#ECECED] " +
                              (i === ans
                                ? 'bg-[#8BB1AC] text-white'
                                : "")
                            }
                            onClick={() => handleAnsChange(i)}
                          >
                            <div
                              className={
                                "w-6 h-6  rounded mt-1.5 mr-3 border-2 border-solid border-[#ECECED]	bg-white" 
                              
                              }
                            >
{i === ans?       <svg width="19"  height="19" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.6668 3.5L5.25016 9.91667L2.3335 7" stroke="#8BB1AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg> :' '}
                      

                            </div>

                           <span className="mt-1 "> {choice.title}</span>
                          </div>
                        ))}
                      <div className="flex justify-center mt-8 p-4 fixed w-full left-0 bottom-10">
                        <Button
                          className="pl-8 pr-8 cursor-pointer rounded-none w-full mx-2 text-center bg-[#0D2937] border-none font-medium py-3"
                          onClick={handlePollSubmit}
                          type="button"
                        >
                          <span className="w-full text-center">Submit</span>
                        </Button>
                      </div>
                    </>
                  ) : (
                    <div className="flex flex-col w-full h-full overflow-hidden ">
                     
                    {/* monitor results for play */}
                      {poll.choices &&
                        poll.choices.map((choice, index) => (
                          <div key={`poll-${eventId}-${index}`} className="mt--7">
                            <h4 className="text-[#0D2937] font-bold	 text-left	mb--3  text-2xl ">
                              {choice.title}{" "}
                            </h4>
                            <div className="flex flex-row">
                              <div
                                style={{
                                  width: `${getPercentage(choice.value)}% `,
                                }}
                                className={"h-6 w-6 bg-[#0D2937] mt-4 mr-2 "+ (index == max? 'bg-[#8BB1AC]':'') }
                              ></div>
                              <h2 className={"text-[#0D2937] text-left	text-2xl mt-3 "+ (index == max? 'text-[#8BB1AC]':'') }>
                                {getPercentage(choice.value)}%
                              </h2>
                            </div>
                          </div>
                        ))}
     <div className="flex justify-center mt-8 p-4 fixed w-full left-0 bottom-10">                        <Button
   className="pl-8 pr-8 cursor-pointer rounded-none w-full mx-2 text-center bg-[#0D2937] border-none font-medium py-3"                          onClick={handlePollBack}
                          type="button"
                        >
                           <span className="w-full text-center">Back</span>
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="flex flex-col w-full h-full overflow-hidden  items-center justify-center">
              <h1 className="text-[#0D2937] text-left	mb-20 text-2xl">
                This Poll doesn't exist!
              </h1>
            </div>
          )}
        </>
      )}
    </div>
  );
}
